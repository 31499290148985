/* eslint-disable no-underscore-dangle */
import { initializeAdobeTracking } from './adobe';

(() => {
  const { utils, storybook } = window.tv2 || {};
  const log = utils.setupLog('⌛ Analytics');

  const initAdobeAfterCMP = () => {
    if (window.__tcfapi === undefined) {
      setTimeout(initAdobeAfterCMP, 50);
      return;
    }

    const callback = (tcData, success) => {
      if (
        tcData.eventStatus === 'useractioncomplete' ||
        tcData.eventStatus === 'tcloaded'
      ) {
        log.info('CMP loaded so continue with initializing Adobe tracking');
        initializeAdobeTracking(utils.isTv2Prod);
      }

      if (success && tcData.eventStatus === 'tcloaded') {
        // remove eventlistener to not get called more than once
        window.__tcfapi('removeEventListener', 2, () => {}, callback);
      }
    };

    window.__tcfapi('addEventListener', 2, callback);
  };

  log.info('Adobe will wait for CMP to load before initializing');
  initAdobeAfterCMP();

  // We can set a storybook flag on the tv2 object to prevent linkpulse and kilkaya from loading
  // These are prone to produce noise in the console, and do not serve a purpose in our storybook
  if (storybook) return;

  // kilkaya
  log.info('Loading kilkaya');
  window.tv2.utils.loadScript('https://cl.k5a.io/6156f35d0662411b5d525212.js');
})();
